<template>
  <div>
    <a-form-model
      ref="sectionEditForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        v-for="(localization, index) in form.localizations"
        :label="locales[locales.findIndex(x => x.lang_id === localization.lang_id)].name + ' name'"
        :key="`name${index}`"
        :prop="`localizations.${index}.title`"
        :rules="[
          { required: true, message: `This field is required`, trigger: 'blur' },
        ]"
      >
        <a-input v-model="localization.title" :placeholder="locales[locales.findIndex(x => x.lang_id === localization.lang_id)].name + ' name'" />
      </a-form-model-item>
      <div class="float-left"><p class="font-size-14 mb-0 font-weight-bold">Viewscreen</p></div>
      <a-form-model-item prop="viewscreens" style="clear:both;">
        <a-select
          v-model="form.tab_id"
          label="Viewscreen"
          ref="select"
          >
          <a-select-option v-for="viewscreen in viewscreens" :key="viewscreen.id" :value="viewscreen.id">
            {{ viewscreen.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="is_active" prop="is_active">
        <a-switch v-model="form.is_active" />
        <span class="ml-2">{{statusIsActive}}</span>
      </a-form-model-item>
      <hr class="hr-bottom">
      <a-row>
        <a-col class="text-right" :span="24">
          <a-button @click="closeModal">
            Cancel
          </a-button>
          <a-button :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
            Add
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import MainService from '@/services/api/apiMainService'

export default {
  name: 'sectionModal',
  props: ['sectionObj', 'viewscreens'],
  components: {},
  data() {
    return {
      submitLoading: false,
      locales: [{ lang_id: 0, name: 'Russian' }, { lang_id: 2, name: 'English' }],
      form: {
        tab_id: this.viewscreens[0].id,
        is_active: this.sectionObj ? this.sectionObj.is_active : true,
        localizations: [{
          lang_id: 0,
          title: this.sectionObj ? this.sectionObj.localizations.find(el => el.lang_id === 0).title : '',
        }, {
          lang_id: 2,
          title: this.sectionObj ? this.sectionObj.localizations.find(el => el.lang_id === 2).title : '',
        }],
      },
      rules: {},
    }
  },
  computed: {
    statusIsActive() {
      return this.form.is_active ? 'Active' : 'Inactive'
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeDuplicationSectionModal')
    },
    submitDuplicateModal(duplicateSection) {
      this.$emit('duplicateSection', duplicateSection, this.form.tab_id)
    },
    onSubmit() {
      this.$refs.sectionEditForm.validate(valid => {
        if (valid) {
          this.duplicateSection()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async duplicateSection() {
      this.submitLoading = true
      return MainService.duplicateSection(this.form, this.sectionObj.id).then((response) => {
        this.submitLoading = false
        const duplicateSection = response.data.data
        this.$notification.success({
          message: 'Section duplicated',
          description: '',
        })
        this.submitDuplicateModal(duplicateSection)
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while section duplicated',
          description: error.message,
        })
      })
    },
  },
}
</script>
<style scoped>
.ant-form-item-label {
  padding: 0px !important;
}
.link-image {
  margin-bottom: 20px;
  display: inline-block;
}
.hr-bottom {
  margin-left: -24px;
  margin-right: -24px;
}
</style>
